import styled from 'styled-components';

import colors from "~/styles/colors";
import bgHeader from '~/assets/bgHeader.svg';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background-color: #FFF;
  background-image: url(${bgHeader});
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 100px;

  @media screen and (max-width: 580px) {
    background-image:none;
    background-color: ${colors.secundary};
    border-bottom: 10px solid ${colors.primary};
    padding-bottom: 20px;
  }
`;

export const Body = styled.header`
  display: flex;
  flex-direction: row;
  width: 940px;
  justify-content: space-between;

  div h1 {
    color: #fff;
    font-size: 36px;
    width: 316px;
    line-height: 60px;
    font-weight: bold;
    padding: 30px 0px;
  }

  .subtitulo {
    display: flex;
    justify-content: center;
    background-color: ${colors.primary};
    border-radius: 20px;
    max-width: 287px;
    margin-bottom: 30px;
  }

  .subtitulo h1 {
    width: 205px;
    font-size: 18px;
    line-height: 30px;
    color: ${colors.title}
  }

  @media screen and (max-width: 760px) {
    padding: 0px 20px;
    flex-direction: column;
  }
`;

export const Img = styled.img`
  width: 460px;
  height: 453px;
  margin-left: -105px;
`;

export const Form = styled.form`
  padding: 30px;
  margin-bottom: auto;
  background-color: #fff;
  width: 350px;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 10px 5px 50px rgba(51, 51, 51, 0.2);

  h1{
    font-size: 20px;
    font-weight: 400;
    color: ${colors.title};
    margin-bottom: 30px;
  }

  label {
    color: ${colors.text};
  }

  input, textarea {
    margin-top: 5px;
    padding-left: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 100%;
    height: 45px;
    border: ${colors.text} solid 1px;
    color: ${colors.text};
  }

  textarea {
    padding-top: 5px;
    font-size: 16px;
    color: ${colors.text};
    height: 90px;
  }

  .chackbox {
    display: flex;
    font-size: 12px;
  }

  input[type='checkbox'] {
    height: 15px;
    width: 15px;
    padding: 0px;
    margin: 0px 10px 20px 0px;
  }

  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: ${colors.switch};
  }

  input:-ms-input-placeholder, textarea::-ms-input-placeholder {
    color: ${colors.switch};
  }

  input::placeholder, textarea::placeholder {
    color: ${colors.switch};
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${colors.secundary};
    color: #fff;
    width: 100%;
    padding: 15px;
    border: none;
    text-transform: uppercase;

    span {
      margin-right: 10px;
    }
  }
`;
