import React from 'react';

import { Container, Body, Card, Collum} from './styles';

import img01 from '~/assets/img01.png';
import img02 from '~/assets/img02.png';
import img03 from '~/assets/img03.png';

function Index() {
  return (
    <Container id="fazemos">
      <Body>
        <Collum>
          <header>
            <h1>
              UmProgramador existe para encurtar a distância entre você e o seu sucesso.
              Como fazemos isso? Nós te mostramos!
            </h1>
          </header>
          <Card className="primaryCard desktop">
            <div>
              <img src={img01} />
            </div>
            <h1>Primeiros passos: Como funciona?</h1>
            <p>Todo o nosso processo é lado a lado com o cliente. Entendemos junto com você qual a sua real necessidade e montamos a estratégia do seu processo. Daí pra frente é a vez de desenvolver. Mas não se preocupe! Você irá testar tudo e nos dizer se estamos no caminho certo (e é claro que estaremos!)</p>
          </Card>
          <Card className="primaryCard mobile">
            <div>
              <img src={img03} />
            </div>
            <h1>Pra começar: Quem somos?</h1>
            <p>Nossa empresa nasceu da combinação de paixão e corporativismo; veio da ideia de fazer com que clientes possam gerenciar seus negócios de forma intuitiva e prática, independente do tamanho da empresa.
O resultado foi e continua sendo a satisfação de cada um que, ao olhar a tela, enxerga seu próprio universo e diz: "era exatamente o que eu queria.</p>
          </Card>
        </Collum>
        <Collum>
          <Card className="secondCard mobile">
            <div>
              <img src={img01} />
            </div>
            <h1>Primeiros passos: Como funciona?</h1>
            <p>Todo o nosso processo é lado a lado com o cliente. Entendemos junto com você qual a sua real necessidade e montamos a estratégia do seu processo. Daí pra frente é a vez de desenvolver. Mas não se preocupe! Você irá testar tudo e nos dizer se estamos no caminho certo (e é claro que estaremos!)</p>
          </Card>
          <Card className="secondCard desktop">
            <div>
              <img src={img03} />
            </div>
            <h1>Pra começar: Quem somos?</h1>
            <p>Nossa empresa nasceu da combinação de paixão e corporativismo; veio da ideia de fazer com que clientes possam gerenciar seus negócios de forma intuitiva e prática, independente do tamanho da empresa.
O resultado foi e continua sendo a satisfação de cada um que, ao olhar a tela, enxerga seu próprio universo e diz: "era exatamente o que eu queria.</p>
          </Card>
          <Card className="img">
            <div>
              <img src={img02} />
            </div>
            <h1>Não sei o que fazer. E agora?</h1>
            <p>Calma, calma! Nossa equipe também pode te ajudar a descobrir!</p> <br />
            <p>
É normal que durante toda essa mudança você se sinta perdido e não saiba exatamente o que precisa. Por isso, estamos prontos para esclarecer todas as suas dúvidas e te ajudar a encontrar a melhor solução.</p>
          </Card>
        </Collum>
      </Body>
    </Container>
  );
}

export default Index;
