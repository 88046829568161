import React, {useState} from 'react';
import { elastic as Menu } from 'react-burger-menu';

import './styles.css';

export default props => {

  const closeMenu = () => document.querySelector(".closeMenu").click()

  return (
    <div>
      <Menu {...props} overlayClassName={"closeMenu"}>
        <a className="menu-item" href="#header" onClick={closeMenu}>
          Home
        </a>
        <a className="menu-item" href="#fazemos" onClick={closeMenu}>
          O que fazemos
        </a>
        <a className="menu-item" href="#portfolio" onClick={closeMenu}>
          Ideias
        </a>
        <a className="menu-item" href="#contatos" onClick={closeMenu}>
          Contatos
        </a>
      </Menu>
    </div>
  );
};
