import styled from 'styled-components';

import colors from "~/styles/colors";
import bgPortfolio from '~/assets/bgPortfolio.svg';
import cubes from '~/assets/cubes.png';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background-color: ${colors.secundary};
  background-image: url(${cubes}), url(${bgPortfolio});
  background-position: right 185px, left top;
  background-size: 100%, inherit;
  background-repeat: no-repeat, no-repeat;
  padding: 200px 0px 400px 0px;

  @media screen and (max-width: 760px) {
    padding: 100px 0px 400px 0px;
  }
`;

export const Body = styled.header`
  display: flex;
  flex-direction: row;
  width: 940px;

  div.cell {
    max-width: 270px;
    margin-right: 120px;
  }

  div.cell img {
    position: absolute;
    z-index: 3;
  }

  div {
    display: flex;
    align-items: center;
  }

  div h1 {
    color: #fff;
    font-size: 36px;
    max-width: 345px;
    line-height: 46px;
    font-weight: 400;
    padding: 30px 0px;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column-reverse;
    padding: 0px 20px;

    div h1 {
      margin-bottom: 100px;
    }
  }
`;

export const Img = styled.img`
`;
