import React from 'react';

import { Container, Body, Img } from './styles';

import facebook from '~/assets/facebook.png';
import instagram from '~/assets/instagram.png';
import whatsapp from '~/assets/whatsapp.png';
import twitter from '~/assets/twitter.png';
import imgFooter from '~/assets/imgFooter.png';

function Footer() {
  return (
    <Container id="contatos">
      <Body>
        <div className="contato">
          <h1>Ei, ficou alguma dúvida? Fala com a gente. Respondemos rapidinho!</h1>
          <ul>
            <li>
              - Telefone: 61 9 9863-2915
            </li>
            <li>
              - E-mail: comercial@umprogramador.com.br
            </li>
            <li>
              - CNPJ: 27.697.211/0001-81
            </li>
          </ul>
          <div>
            <a href="https://www.facebook.com/umprogramador" target="_blanc"><img src={facebook} /></a>
            <a href="https://www.instagram.com/up_umprogramador/" target="_blanc"><img src={instagram} /></a>
            <a href="https://api.whatsapp.com/send?phone=556198632915" target="_blanc"><img src={whatsapp} /></a>
            <a href="#"><img src={twitter} /></a>
          </div>
        </div>
        <div>
            <img src={imgFooter} className="imgFooter" />
        </div>
      </Body>
    </Container>
  );
}

export default Footer;
