import styled from 'styled-components';

import colors from "~/styles/colors";
import bgHeader from '~/assets/bgHeader.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #FFF;
`;

export const Body = styled.section`
  display: flex;
  flex-direction: row;
  width: 940px;
  justify-content: space-between;
  padding-top: 100px;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  @media screen and (max-width: 760px) {
    padding: 0px 20px;
    flex-direction: column;

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }
`;

export const Collum = styled.article`

  display: flex;
  flex-direction: column;

  header {
    max-width: 420px;
    padding-bottom: 30px;
  }

  header h1 {
    color: ${colors.title};
    font-size: 28px;
    line-height: 46px;
    font-weight: 400;
  }

  .secondCard {
    margin-top: 0px;
  }

  .img > div > img{
    margin-top: -60px;
  }

  @media screen and (max-width: 760px) {

    header h1 {
      padding: 70px 0px;
    }
    .secondCard{
      margin-top: 100px;
    }

    .primaryCard {
      margin-top: 0px;
    }
  }
`;

export const Card = styled.div`
  max-width: 380px;
  border-radius: 10px;
  box-shadow: 10px 5px 50px rgba(51, 51, 51, 0.2);
  padding: 34px;
  margin-top: 100px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div > img {
  }

  h1 {
    font-size: 20px;
    color: ${colors.title};
    padding: 30px 0px;
    font-weight: 400;
  }

  p {
    color: ${colors.text};
  }

  @media screen and (max-width: 760px) {
    margin-top: 100px;
  }
`;
