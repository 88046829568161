import axios from "axios";

const api = axios.create({
	// baseURL: "http://localhost/umprogramador_api/public/api",
	baseURL: "https://api.umprogramador.com.br/api",
	headers: {
		"Content-Type": "application/json",
	},
});

export default api;
