import React from 'react';

import Sidebar from '~/component/Menu';

import { Container, Body, Logo, NavIcon } from './styles';

import LogoImg from "~/assets/logo.png";

function Menu() {
  return (
    <Container>
      <Body>
        <Logo src={LogoImg} />
        <Sidebar right />
      </Body>
    </Container>
  );
}

export default Menu;
