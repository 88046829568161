import React from 'react';

import Banner from "~/component/Banner";

import { Container, Body, Img } from './styles';

import cell from '~/assets/cell.png';

function Portfolio() {
  return (
    <Container id="portfolio">
      <Body>
        <div className="cell">
          <Img src={cell}/>
          <Banner />
        </div>
        <div>
          <h1>As ideias que saíram do papel.</h1>
        </div>
      </Body>
    </Container>
  );
}

export default Portfolio;
