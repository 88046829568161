import React from 'react';

import Menu from "./menu";
import Header from "./header";
import Body from "./body";
import Portfolio from "./portfolio";
import Footer from "./footer";

function Index() {
  return (
    <>
      <Menu />
      <Header/>
      <Body/>
      <Portfolio/>
      <Footer/>
    </>
  );
}

export default Index;
