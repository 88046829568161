import React from "react";

import "react-awesome-slider/dist/styles.css";

import { Container } from "./styles";

import withAutoplay from "react-awesome-slider/dist/autoplay";

import Tmk from '~/assets/portfolio/tmk.png';
import tmk2 from '~/assets/portfolio/tmk2.png';
import tmk3 from '~/assets/portfolio/tmk3.png';
import filmes01 from '~/assets/portfolio/filmes01.png';
import filmes02 from '~/assets/portfolio/filmes02.png';
import filmes03 from '~/assets/portfolio/filmes03.png';
import marktclub from '~/assets/portfolio/marktclub.png';
import marktclub2 from '~/assets/portfolio/marktclub2.png';
import marktclub3 from '~/assets/portfolio/marktclub3.png';
import ja01 from '~/assets/portfolio/ja01.png';
import ja02 from '~/assets/portfolio/ja02.png';
import ja03 from '~/assets/portfolio/ja03.png';
import ja04 from '~/assets/portfolio/ja04.png';

const AutoplaySlider = withAutoplay(Container);

const Banner = () => {
	return (
		<AutoplaySlider
			play={true}
			cancelOnInteraction={false}
			interval={3000}
      bullets={false}
      buttons={false}
		>
			<div data-src={marktclub} />
			<div data-src={marktclub2} />
			<div data-src={marktclub3} />
			<div data-src={ja01} />
			<div data-src={ja02} />
			<div data-src={ja03} />
			<div data-src={ja04} />
			<div data-src={Tmk} />
			<div data-src={tmk2} />
			<div data-src={tmk3} />
			<div data-src={filmes01} />
			<div data-src={filmes02} />
			<div data-src={filmes03} />
		</AutoplaySlider>
	);
};

export default Banner;
