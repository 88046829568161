import React, {useState} from 'react';

import { Container, Body, Img, Form } from './styles';

import InputMask from "react-input-mask";

import banner from '~/assets/banner01.png';

import api from "~/services/api";

import { Alert, Loader } from 'rsuite';

function Header() {

	const [nome, setNome] = useState("");
	const [telefone, setTelefone] = useState("");
	const [email, setEmail] = useState("");
	const [mensagem, setMensagem] = useState("");
	const [whatsapp, setWhatsapp] = useState(false);
	const [loading, setLoading] = useState(false);

  const handleContato = async (e) => {
    e.preventDefault();

    setLoading(true);

    api.post('/contato', {
      name: nome,
      mail: email,
      phone: telefone,
      message: mensagem,
      whatsapp
    }).then(response => {
      setNome('');
      setEmail('');
      setTelefone('');
      setMensagem('');
      setWhatsapp(false);
      Alert.success(response.data.message);
    }).catch(error => {
      Alert.error(error.response.data.message);
    }).finally(() => setLoading(false))

  }
  return (
    <Container id="header">
      <Body>
        <div>
          <h1>Nenhuma ideia é pequena demais.</h1>

          <div className="subtitulo">
            <h1>Aplicativos, sistemas de gerenciamento e sites.</h1>
          </div>

          <Img src={banner}/>
        </div>
        <Form onSubmit={handleContato} method="POST">
          <h1>O mundo mudou e o UP te ajuda a mudar também.</h1>

          <label>Como quer ser chamado?</label>
          <input
            value={nome}
            type="text" name="nome"
            placeholder="Seu nome"
            onChange={(e) => {
              setNome(e.target.value);
            }} />

          <label>E-mail ou telefone? Você decide.</label>
          <input
            value={email}
            type="text"
            name="email"
            placeholder="Seu e-mail"
            onChange={(e) => {
              setEmail(e.target.value);
            }} />

          <InputMask
            placeholder="Seu telefone"
            mask="(99) 99999-9999"
            value={telefone}
            onChange={(e) => {
              setTelefone(e.target.value);
            }}
          />

          <div className="chackbox">
            <input
              checked={whatsapp}
              type="checkbox"
              onChange={() => {
                setWhatsapp(!whatsapp);
              }}
            />
            <label>aceito ser contactado via whatsapp</label><br />
          </div>

          <label>Fala pra gente: O que você precisa?</label>
          <textarea
            value={mensagem}
            name="mensagem"
            placeholder="Sua mensagem"
            onChange={(e) => {
              setMensagem(e.target.value);
            }} />

          <button>{loading && <Loader />} <span>Enviar</span></button>
        </Form>
      </Body>
    </Container>
  );
}

export default Header;
