import styled from "styled-components";

import AwesomeSlider from "react-awesome-slider";

import colors from "~/styles/colors";

export const Container = styled(AwesomeSlider)`
  width: 230px;
  height: 400px;
  margin-left: 18px;
  --loader-bar-color: ${colors.primary} !important;

  img {
    object-fit: cover;
  }
`;
