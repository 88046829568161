import styled from 'styled-components';

import colors from "~/styles/colors";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.backgroundFooter};
  border-radius: 0px 200px 0px 0px;
  margin-top: -200px;
  padding: 30px;
`;

export const Body = styled.footer`
  display: flex;
  flex-direction: row;
  width: 940px;
  justify-content: space-between;

  div.contato {
    display: flex;
    flex-direction: column;
    color: #fff;
  }

  div.contato h1 {
    width: 290px;
    font-size: 20px;
    line-height: 30px;
    padding: 0px 0px 30px 0px;
  }

  div.contato ul {
  }

  div.contato ul li{
    list-style: none;
    margin-bottom: 20px;
  }

  div.contato img {
    padding: 8px;
  }

  div img.imgFooter {
    margin-top: 15px;
  }

  div.contato img:first-child {
    padding-left: 0px;
  }

  div h1 {
    color: #fff;
    font-size: 36px;
    max-width: 345px;
    line-height: 46px;
    font-weight: 400;
    padding: 30px 0px;
  }

  @media screen and (max-width: 760px) {

    div img.imgFooter {
      display:none;
    }

    div.contato ul li {
      font-size: 14px;
    }
  }
`;

export const Img = styled.img`
`;
