import styled from 'styled-components';

import colors from "~/styles/colors";

export const Container = styled.nav`
  position:fixed;
  z-index: 4;
  display: flex;
  width: 100%;
  height: 80px;
  background-color: ${colors.secundary};
  border-bottom: ${colors.primary} solid 1px;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  width: 940px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 760px) {
    padding: 0px 20px;
  }
`;

export const Logo = styled.img`
  width: 45px;
  height: 60px;
`;

export const NavIcon = styled.img`
  width: 48px;
  height: 48px;
`;
