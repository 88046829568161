const colors = {
	primary: "#F7B618",
	secundary: "#007AFF",
	background: "#fff",
	backgroundFooter: "#333333",
	backgroundSecundary: "#202020",
	green: "#2D4D59",
	bluegreen: "#498BA6",
	blue: "#C2EDF2",
	switch: "#d1d1d1",
	plots: "rgb(135, 134, 139)",
	textDescription: "#717171",
	title: "#333333",
	text: "#999999",
	divider: "#C0C0C0",
	gray: "#CCCCCC",
	grayDark: "#9B9A9B",
	borderColor: "#D8D8D8",
	red: "#D43A4F",
};

export default colors;
