import GlobalStyle from "./styles/globals";

import Screens from "./screen/index";

function App() {
  return (
    <>
      <Screens />
      <GlobalStyle />
    </>
  );
}

export default App;
